@if ($is-tena-theme) {
  .market-specific-text {
    @include font-size-and-line-height(16px, 25px);

    font-family: $fontfamily-regular;
    color: $home-color;
  }

  .market-specific-text a {
    color: $cta-button-text-color;
  }
}