$theme-name: "jobst";

// Colors
$color-primary: #0F265C;
$color-primary-light: #D92912;
$color-light-blue: #00AADB;
$color-light: #D7D9E4;
$color-orange: #EE5340;
$color-gray: #4A4949;
$color-gray-50: #A4A4A4;
$color-light-50: #EBECF1;
$color-light-25: #F5F5F8;
$home-color: $color-primary;
$color-heading: #0F265C;
$border-color: #E6E7E8;
$color-aluminium: #aabcc4;
$background-color: $color-light-25;
$error-color: $color-primary-light;
$border-color-alt: $color-gray;

// Typography
$fontfamily-interstate-regular: Interstate, $fontfamily-fallback;
$fontfamily-interstate-bold: InterstateBold, $fontfamily-fallback;
$fontfamily-interstate-light: InterstateLight, $fontfamily-fallback;
$fontfamily-irregular-bold: IrregularBold, $fontfamily-fallback;

$fontfamily-regular: $fontfamily-interstate-regular;
$fontfamily-regular-bold: $fontfamily-interstate-bold;
$fontfamily-regular-light: $fontfamily-interstate-light;
$fontfamily-titles-normal: $fontfamily-interstate-regular;
$fontfamily-titles-thin: $fontfamily-interstate-regular;
$fontfamily-header: $fontfamily-interstate-regular;
$fontfamily-header-thin: $fontfamily-interstate-regular;

// Font sizes
$font-size-h1: (def: 48px, sm: 28px);
$font-size-h2: (def: 40px, md: 28px, sm: 22px);
$font-size-h3: (def: 28px);
$font-size-h4: (def: 22px);
$font-size-paragraph: 18px;
$font-size-section-title: (def: 28px);
$font-size-h1-category-page-title: (def: 48px, sm: 40px);
$line-height-h1-category-page-title: (def: 54px, sm: 44px);
$font-size-h2-landing-page-title: (def: 40px, sm: 28px);
$line-height-h2-landing-page-title: (def: 44px, sm: 32px);

// Line height
$line-height-paragraph: 26px;
$line-height-section-title: (def: 32px);
$line-height-boxes-text: (def: 26px);
$paragraph-line-height: 26px;

// Buttons
$font-size-btn: 18px;
$btn-border-radius: 3px;
$btn-padding: 6px 20px 6px 20px;
$btn-padding-icon: 48px;
$btn-font-family: $fontfamily-regular;
$btn-weight: 400;
$color-mediaui-btn-bg: rgba(0, 0, 0, 0.54);

// Accordion
$accordion-item-bg: $color-light;
$accordion-item-color: $color-primary;
$accordion-fontfamily: $fontfamily-regular;
$accordion-description-color: $color-gray;
$font-size-accordion-title: (def: 18px);
$line-height-accordion-title: (def: 26px);
$accordion-title-font-size: (def: 40px, sm: 28px);
$accordion-title-line-height: (def: 44px, sm: 32px);
$accordion-title-small-font-size: (def: 28px, sm: 22px);
$accordion-title-small-line-height: (def: 32px, sm: 26px);

// Header
$header-menu-item-hover-background: #B42310;
$menu-item-expand-keyboard-color: $color-white;
$item-sample-title-font-size: (def: 18px);
$item-sample-title-line-height: (def: 26px);
$header-links-color: $color-primary;
$header-border-bottom-color: $color-primary-light;
$header-border-bottom-width: 8px;
$font-size-menu-item: (def: 18px, sm: 22px);
$menu-submenu-bg-color: $color-light-25;
$submenu-link-color: $color-primary;
$header-menu-item-title-scroll-color: $color-white;
$header-menu-item-title-scroll-hover: $color-white;
$menu-submenu-bg-color: $color-white;
$mobile-header-search-close-icon-color: $color-primary;
$mobile-header-details-position-bottom: 12px;
$header-country-selector-label-font-size: 14px;
$header-country-selector-label-line-height: 18px;
$header-country-selector-label-color: #4A4949;

// Search
$font-size-input-search: (def: 18px);
$line-height-input-search: (def: 26px);
$input-search-border-color: $color-gray;
$input-search-border-color-active: $color-gray;
$input-search-header-color: $color-gray;
$btn-search-icons-position-top: 22px;
$pagination-arrow-icon-color: $color-primary;
$pagination-background-color: #F2F7FA;
$pagination-hover-background-color: $color-primary-light;
$pagination-selected-background-color: $color-primary-light;
$pagination-selected-hover-background-color: $color-primary-light;
$btn-search-reset-position-top: 24px;
$search-article-icon-color: #004E9E;

// Breadcumbs
$breadcrumb-active-link-color: $color-primary;
$breadcrumb-color: $color-gray;
$breadcrumb-arrow-color: $color-primary-light;
$breadcrumb-hover-color: $color-primary-light;
$breadcrumbs-bg-color: $color-light-25;

// Footer
$font-size-footer-column-title: 22px;
$line-height-footer-column-title: 26px;
$font-size-footer-paragraph: 18px;
$footer-line-height-paragraph: 26px;
$footer-line-height-links: 26px;
// The variable was added due to a problem with the mixin for the buttons
$icon-short-arrow-right: "\e620";
$footer-disclaimer-line-height: (def: 36px, sm: 26px);
$footer-disclaimer-heading-font-family: $fontfamily-regular-bold;
$footer-disclaimer-heading-color: $color-primary;
$footer-disclaimer-footnote-font-size: (def: 18px);
$footer-disclaimer-footnote-line-height: (def: 28px, sm: 26px);
$footer-disclaimer-footnote-color: $color-gray;
$footer-disclaimer-border-color: $color-gray-50;
$footer-country-selector-label-font-size: 18px;
$footer-country-selector-label-line-height: 26px;
$footer-country-selector-label-color: rgba($color-white, 0.67);

// Promo banner
$promo-banner-title-font: $fontfamily-irregular-bold;
$promo-banner-desc-font: $fontfamily-regular;
$promo-banner-title-font-size: (def: 40px, sm: 28px);
$promo-banner-title-line-height: (def: 44px, sm: 32px);
$promo-banner-desc-font-size: (def: 18px);
$promo-banner-desc-line-height: (def: 26px);
$promo-banner-bg-color: $color-primary-light;

// Box shadow
$box-shadow-color: rgba(15, 38, 92, 0.15);

// Popup buy now
$popup-product-title-font-size: (def: 28px, xs: 22px);
$popup-product-title-line-height: (def: 32px, xs: 26px);
$popup-product-title-font-family: $fontfamily-regular-bold;
$popup-product-title-color: $color-primary;
$popup-product-close-button-background: $color-gray-50;
$popup-product-select-line-height: (def: 26px);
$popup-product-select-color: $color-gray;
$popup-product-select-font-family: $fontfamily-regular-light;
$popup-product-details-font-size: (def: 18px);
$popup-product-details-line-height: (def: 26px);
$popup-product-details-font-fammily: $fontfamily-regular-light;
$popup-product-details-color: $color-gray;
$popup-product-details-border-color: #D6DEE3;

// Create
$font-size-section-description-title: 28px;

// Pushbox
$push-box-title-transform: none;
$font-size-pushbox-text: (def: 18px);
$line-height-pushbox-text: (def: 26px);
$pushbox-padding: (def: 24px);
$push-box-border-radius: 3px;
$promo-box-shadow: 1px 4px 16px 0 $box-shadow-color;
$push-box-background-gradient: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);

// Carousel
$swiper-pagination-color: transparent;
$swiper-pagination-border-color: $color-primary;
$swiper-pagination-active-color: $color-primary-light;
$swiper-pagination-active-border-color: $color-primary-light;
$swiper-pagination-color-hover: transparent;
$swiper-pagination-border-color-hover: $color-primary-light;
$swiper-pagination-active-color-hover: $color-primary-light;
$swiper-pagination-active-border-color-hover: $color-primary-light;

// Box carousel
$box-carousel-title-font-size: (def: 28px);
$box-carousel-title-line-height: (def: 32px);
$box-carousel-subtitle-font-size: (def: 18px);
$box-carousel-subtitle-line-height: (def: 26px);
$box-carousel-text-font-size: (def: 18px);
$box-carousel-text-line-height: (def: 26px);
$carousel-background-gradient: linear-gradient(180deg, rgba($color-black, 0) 0%, rgba($color-black, 0.54) 100%);

// Boxes
$font-size-boxes-title: (def: 22px);
$line-height-boxes-title: (def: 26px);
$font-size-boxes-text: (def: 18px);
$line-height-boxes-text: (def: 26px);

// Gallery carousel
$font-size-gallery-carousel-heading: (def: 28px);
$gallery-carousel-heading-line-height: (def: 32px);
$font-size-gallery-carousel-subheading: (def: 18px);
$gallery-carousel-subheading-line-height: (def: 26px);

// Banner
$image-overlay-text-font-size: (def: 40px);
$image-overlay-text-line-height: (def: 44px);
$font-size-caption-image: 22px;
$font-size-caption-image-subheader: 18px;
$caption-background-transparent: rgba($color-primary, 0.81);
$btn-play-background-transparent: rgba($color-white, 0.67);

// Product intro
$product-intro-title-font-size: (def: 28px);
$product-intro-title-line-height: (def: 32px);
$product-intro-title-font: $fontfamily-regular-bold;
$product-intro-title-color: $color-primary;
$image-carousel-arrow-color: $color-primary;
$product-intro-thumbnail-slider-icon: $color-primary;
$product-carousel-scrollbar-color: $color-primary-light;
$product-intro-accordion-title-font-size: (def: 22px);
$product-intro-accordion-title-line-height: (def: 26px);
$product-intro-accordion-arrow: $color-primary-light;
$product-intro-accordion-border: #D6DEE3;
$product-intro-accordion-benefits-text-color: #666;
$product-intro-accordion-how-to-content-line-height: 26px;

// Campaign
$font-size-campaign-image-title-large: (def: 40px);
$line-height-campaign-image-title-large: (def: 44px);
$campaing-title-font-size: (def: 28px);
$campaing-title-line-height: (def: 32px);
$campaing-subtitle-font-size: (def: 18px);
$campaing-subtitle-line-height: (def: 26px);
$font-size-caption-image-header: (def: 28px);
$line-height-caption-image-header: (def: 32px);
$font-size-caption-image-subheader: (def: 18px);
$line-height-campaign-image-subheader: (def: 26px);

// Campaign - Media
$campaign-overlay-cta-margin-top: 16px;

// Item list
$item-list-paragraph-color: $color-gray;
$line-height-items-list: 26px;
$font-size-items-list: 18px;

// Section title
$font-size-section-title-large: (def: 40px);
$line-height-section-title-large: (def: 44px);
$letter-spacing-section-title-large: unset;

// Page intro
$intro-title-font-family: $fontfamily-regular-bold;
$intro-title-font-size: (def: 48px, sm: 40px);
$intro-title-line-height: (def: 54px, sm: 44px);
$intro-text-font-size: (def: 18px);
$intro-line-height: (def: 26px);
$intro-text-color: $color-gray;
$intro-text-font-family: $fontfamily-regular-light;
$product-intro-guide-popup-label-color: $color-primary;
$product-intro-label: $fontfamily-regular;
$product-intro-label-font-size: (def: 18px);
$product-intro-label-line-height: (def: 26px);
$product-intro-panel-header: $fontfamily-regular-bold;
$product-intro-panel-close-button: transparent;

// Call to action
$cta-background-color: $color-primary;
$cta-title-font: $fontfamily-regular-bold;
$cta-title-transform: normal;
$font-size-cta-title: (def: 28px);
$line-height-cta-title: (def: 32px);

// Contact us form
$contas-us-form-background: $color-light-50;

// Country selector
$country-selector-title-color: $color-primary;
$menu-arrow-border-color: $color-white;

// Product card
$product-box-background: $color-white;
$product-badge-small-background: $color-primary;
$product-paragraph-line-height: 26px;
$product-text-color: $color-gray;
$absorbency-color-drop-style-filled: $color-primary;
$product-details-info-icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clip-path="url(#clip0_4076_254)"><path d="M8.29999 8.59375L11.1625 8.235L9.91124 14.1163C9.82249 14.5413 9.94749 14.7825 10.2912 14.7825C10.5337 14.7825 10.8987 14.695 11.1487 14.475L11.0387 14.995C10.68 15.4275 9.88874 15.7425 9.20749 15.7425C8.32874 15.7425 7.95499 15.215 8.19749 14.0937L9.11999 9.75875C9.19999 9.3925 9.12749 9.26 8.75999 9.1725L8.19749 9.06875L8.29999 8.59375Z" fill="#0F265C"/><path d="M10.8839 6.50888C11.1183 6.27446 11.25 5.95652 11.25 5.625C11.25 5.29348 11.1183 4.97554 10.8839 4.74112C10.6495 4.5067 10.3315 4.375 9.99999 4.375C9.66847 4.375 9.35053 4.5067 9.11611 4.74112C8.88169 4.97554 8.74999 5.29348 8.74999 5.625C8.74999 5.95652 8.88169 6.27446 9.11611 6.50888C9.35053 6.7433 9.66847 6.875 9.99999 6.875C10.3315 6.875 10.6495 6.7433 10.8839 6.50888Z" fill="#0F265C"/><path fill-rule="evenodd" clip-rule="evenodd" d="M17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34783 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34783 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34783 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34783 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711ZM3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872Z" fill="#0F265C"/></g><defs><clipPath id="clip0_4076_254"><rect width="20" height="20" fill="white"/></clipPath></defs></svg>';
$product-details-info-icon-hover: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clip-path="url(#clip0_4076_254)"><path d="M8.29999 8.59375L11.1625 8.235L9.91124 14.1163C9.82249 14.5413 9.94749 14.7825 10.2912 14.7825C10.5337 14.7825 10.8987 14.695 11.1487 14.475L11.0387 14.995C10.68 15.4275 9.88874 15.7425 9.20749 15.7425C8.32874 15.7425 7.95499 15.215 8.19749 14.0937L9.11999 9.75875C9.19999 9.3925 9.12749 9.26 8.75999 9.1725L8.19749 9.06875L8.29999 8.59375Z" fill="#D92912"/><path d="M10.8839 6.50888C11.1183 6.27446 11.25 5.95652 11.25 5.625C11.25 5.29348 11.1183 4.97554 10.8839 4.74112C10.6495 4.5067 10.3315 4.375 9.99999 4.375C9.66847 4.375 9.35053 4.5067 9.11611 4.74112C8.88169 4.97554 8.74999 5.29348 8.74999 5.625C8.74999 5.95652 8.88169 6.27446 9.11611 6.50888C9.35053 6.7433 9.66847 6.875 9.99999 6.875C10.3315 6.875 10.6495 6.7433 10.8839 6.50888Z" fill="#D92912"/><path fill-rule="evenodd" clip-rule="evenodd" d="M17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34783 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34783 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34783 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34783 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711ZM3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872Z" fill="#D92912"/></g><defs><clipPath id="clip0_4076_254"><rect width="20" height="20" fill="white"/></clipPath></defs></svg>';

// Power reviews
$pr-idle-color: $color-primary;
$pr-star-color: $color-primary;
$pr-star-0: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 115"><polygon points="88 103 58 87 27 103 33 69 8 45 43 40 58 9 73 40 107 45 82 69 88 103" fill="#{$pr-idle-color}" stroke="#{$color-primary}" stroke-width="2"/></svg>';
$pr-star-25: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 115"><polygon points="107 45 73 40 58 9 43 40 33 41 33 69 33 69 33 69 33 100 58 87 88 103 82 69 107 45" fill="#{$pr-idle-color}" stroke="#{$color-primary}" stroke-width="2"/><polygon points="33 69 33 69 33 69 33 69" fill="none" stroke="#{$color-primary}" stroke-width="2" /><polygon points="27 103 33 100 33 69 27 103" fill="#{$pr-star-color}" stroke="#{$color-primary}" stroke-width="2"/><polygon points="8 45 33 69 33 41 8 45" fill="#{$pr-star-color}" stroke="#{$color-primary}" stroke-width="2"/></svg>';
$pr-star-50: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 115"><polygon points="58 87 27 103 33 69 8 45 43 40 58 9 58 87" fill="#{$pr-star-color}"/><polygon points="88 103 58 87 58 9 73 40 107 45 82 69 88 103" fill="#{$pr-idle-color}" stroke="#{$color-primary}" stroke-width="2"/></svg>';
$pr-star-75: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 115"><polygon points="82 69 107 45 82 41 82 100 88 103 82 69" fill="#{$pr-idle-color}" stroke="#{$color-primary}" stroke-width="2"/><polygon points="73 40 58 9 43 40 8 45 33 69 27 103 58 87 82 100 82 41 73 40" fill="#{$pr-star-color}" stroke="#{$color-primary}" stroke-width="2"/></svg>';
$pr-star-100: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 115"><polygon points="88 103 58 87 27 103 33 69 8 45 43 40 58 9 73 40 107 45 82 69 88 103" fill="#{$pr-star-color}" stroke="#{$color-primary}" stroke-width="2"/></svg>';

// Image Carousel
$image-carousel-thumbnail-bg-alt: $color-light-25;
$image-carousel-enlarge: url("../img/jobst/jobst-icon-enlarge.png");
$image-carousel-enlarge-stroke-color: $color-primary;

// Product filter
$product-filter-arrow-color: $color-primary;

// Sitemap
$sitemap-list-title-font-size: 18px;
$sitemap-list-item-hover-color: $color-primary-light;

// Quotation (Testimonial)
$testimonials-quote-font-family: $fontfamily-regular-bold;
$testimonials-quote-color: $color-white;
$testimonials-quote-letter-spacing: unset;
$testimonials-quote-info-font-size: 18px;
$testimonials-quote-info-line-height: 26px;
$testimonials-quote-info-font-family: $fontfamily-regular-light;
$testimonials-quote-info-color: $color-white;
$testimonials-full-width-background: $color-primary;
$testimonials-quote-left-img: '../img/quotation/ic-quotation-jobst-left.png';
$testimonials-quote-right-img: '../img/quotation/ic-quotation-jobst-right.png';

// Dealer Locator
$dealer-locator-options-bg-color: $color-primary;
$dealer-locator-result-selector-btns-border: #00000026;
$dealer-locator-search-results-text-color: #4A4949;
$dealer-locator-search-btn: #f0593d;
$dealer-locator-options-color: #333;
$dealer-locator-search-results-bold-color: #0F265C;
$dealer-locator-search-results-border-color: #00000026;
$dealer-locator-view-marker-btn-bg: #00add9;

// Country list submenu
$country-language-selected-color: #D92912;
$country-language-selected-font-weight: 400;
